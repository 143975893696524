import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { groupActions } from '../../redux/actions';
import Header from '../../shared/layout/header/Header';
import Layout from '../../shared/layout/Layout';
import InputIcon from '../../shared/common/InputIcon';
import AddGroup from './components/AddGroup';
import ExportGroups from './components/ExportGroups';
import TableGroups from './components/TableGroups';
import DefaultWrapper from '../../components/analytics/DefaultWrapper';
import GroupsSkt from './components/GroupsSkt';
import { ButtonsWrapperGroupsPage, ContainerGroupsPage, HeaderWrapperGroupsPage } from './styled-components/groups.styled.components';
//import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const Groups = () => {
    // REDUX
    const dispatch = useDispatch();

    const ondemand_id = useSelector(state => state.user.user.id);
    const groups = useSelector(state => state.group.groups);
    const loadingGroups = useSelector(state => state.group.loadingGroups);

    // STATE
    const [search, setSearch] = useState('');
    // const [exported, setExported] = useState(null)

    // EFFECTS
    useEffect(() => {
        if (groups === null) {
            dispatch(groupActions.getGroupsRequest({ ondemand_id }));
        }
    }, []); // eslint-disable-line

    // FUNCTIONS
    function handleSearch(e) {
        setSearch(e.target.value);
    }

    // function handleExport(e) {
    // 	setExported(e.target.value);
    // }

    // RETURN
    return (
        <Layout header={<Header route="Grupos" />}>
            <ContainerGroupsPage>
                {loadingGroups ? (
                    <GroupsSkt />
                ) : groups === null ? (
                    <DefaultWrapper title="No hay grupos ingresados" columns="span 8" />
                ) : (
                    <>
                        <HeaderWrapperGroupsPage>
                            <InputIcon value={search} onChange={handleSearch} />
                            <ButtonsWrapperGroupsPage>
                                <AddGroup />
                                <ExportGroups groups={groups?.filter(x => x.nombre.toLowerCase().includes(search.toLowerCase()))} />
                            </ButtonsWrapperGroupsPage>
                        </HeaderWrapperGroupsPage>
                        <TableGroups id="table-groups" groups={groups.filter(x => x.nombre.toLowerCase().includes(search.toLowerCase()))} />
                    </>
                )}
            </ContainerGroupsPage>
        </Layout>
    );
};

export default Groups;
