import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import InputIcon from '../../shared/common/InputIcon';
import NoResultsFound from '../../shared/common/NoResultsFound';
import DefaultSorter from '../../shared/common/DefaultSorter';
import { contentActions } from '../../redux/actions';
import CourseALabCard from './CourseALabCard';
import { ModalProvider } from '../../hooks/ModalContext';
import DefaultStateFilter from '../filters/DefaultStateFilter';
import CourseCardSkeleton from './CourseCardSkeleton';

const TabALabCourses = props => {
    const { user } = props;
    const estados = [
        { id: 0, name: 'Todos', value: 'Todos' },
        { id: 1, name: 'Asignados', value: 'Asignados' },
        { id: 2, name: 'No Asignados', value: 'No Asignados' },
    ];

    //REDUX
    const dispatch = useDispatch();
    const content = useSelector(state => state.content.content);
    const loadingContent = useSelector(state => state.content.loadingContent);

    // STATE
    const [search, setSearch] = useState('');
    const [courses, setCourses] = useState(content);
    const [coursesFiltered, setCoursesFiltered] = useState([]);
    const [currentState, setCurrentState] = useState({ id: 0, name: 'Todos', value: 'Todos' });
    const [order, setOrder] = useState('date-desc');
    const [changeState, setChangeState] = useState(false);

    // EFFECTS
    useEffect(() => {
        if (content === null) {
            dispatch(contentActions.getContentRequest(user.id));
        } else {
            setCourses(content);
            setCoursesFiltered(content);
        }
        // eslint-disable-next-line
    }, [content]);

    useEffect(() => {
        if (search.length !== 0) {
            searchcourses(courses, search);
        } else {
            setCourses(content);
        }
        // eslint-disable-next-line
    }, [search]);

    useEffect(() => {
        if (order === 'date-asc') {
            sortByIdAsc();
        } else if (order === 'date-desc') {
            sortByIdDes();
        }
        // eslint-disable-next-line
    }, [order]);

    useEffect(() => {
        if (currentState.name === 'Todos') {
            setCoursesFiltered(content);
        }
        if (currentState.name === 'Asignados') {
            setCoursesFiltered(courses?.filter(value => value.added === true));
        }
        if (currentState.name === 'No Asignados') {
            // console.log('currentState', currentState);
            setCoursesFiltered(courses?.filter(value => value.added === false));
        }
        setChangeState(true);
        setSearch('');
        // eslint-disable-next-line
    }, [currentState.name]);

    useEffect(() => {
        setTimeout(() => {
            setChangeState(false);
        }, 200);
    }, [changeState]);

    // FUNCTIONS
    const handleSearch = e => {
        setSearch(e.target.value);
        // setCurrentState(null);
    };

    const searchcourses = (array, search) => {
        let newArray = [];
        array.forEach(item => {
            if (item.nombre_publicacion?.toLowerCase().includes(search.toLowerCase())) {
                newArray.push(item);
            }
        });
        setCoursesFiltered(newArray.length === 0 ? [] : newArray);
    };

    const onOrdersChange = newOrders => {
        setOrder(newOrders);
    };

    const sortByIdDes = () => {
        //más reciente
        setCoursesFiltered(
            courses?.sort((a, b) => {
                return b.id > a.id;
            }),
        );
        setChangeState(true);
        setSearch('');
        // setCurrentState(null);
    };

    const sortByIdAsc = () => {
        //más antiguo
        setCoursesFiltered(
            courses?.sort((a, b) => {
                return a.id > b.id;
            }),
        );
        setChangeState(true);
        setSearch('');
        // setCurrentState(null);
    };

    //Filtro DefaultStateFilter
    const handleCurrentState = newValue => {
        console.log('newValue', newValue);
        const estadoEncontrado = estados.find(estado => estado.name === newValue);
        if (estadoEncontrado) {
            setCurrentState(estadoEncontrado);
        }
    };

    if (!courses || loadingContent || content === null) {
        return (
            <>
                <HeaderWrapper>
                    <Left>
                        <InputIcon value={search} onChange={handleSearch} />
                        <DefaultStateFilter currentState={currentState} handleCurrentState={handleCurrentState} estados={estados} />
                    </Left>
                    <Rigth>
                        <DefaultSorter onOrdersChange={onOrdersChange} order={order} />
                    </Rigth>
                </HeaderWrapper>
                <GridValues>
                    <CourseCardSkeleton />
                    <CourseCardSkeleton />
                    <CourseCardSkeleton />
                    <CourseCardSkeleton />
                    <CourseCardSkeleton />
                    <CourseCardSkeleton />
                </GridValues>
            </>
        );
    }
    return (
        <>
            <HeaderWrapper>
                <Left>
                    <InputIcon value={search} onChange={handleSearch} />
                    <DefaultStateFilter currentState={currentState} handleCurrentState={handleCurrentState} estados={estados} />
                </Left>
                <Rigth>
                    <DefaultSorter onOrdersChange={onOrdersChange} order={order} />
                </Rigth>
            </HeaderWrapper>
            {coursesFiltered?.length > 0 ? (
                <GridValues>
                    {coursesFiltered?.map(
                        value =>
                            value.id !== undefined && (
                                <ModalProvider key={value.id}>
                                    <CourseALabCard value={value} key={value.id} artId={value.id} />
                                </ModalProvider>
                            ),
                    )}
                </GridValues>
            ) : search.length === 0 && currentState === 'Todos' ? (
                <NoResultsFound message="No es posible acceder al contenido, por favor comunicate con el administrador." />
            ) : (
                coursesFiltered?.length === 0 && <NoResultsFound message="No se encuentran resultados para tu búsqueda" />
            )}
        </>
    );
};

export default TabALabCourses;

const GridValues = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    height: auto;
    grid-gap: 1.5rem;
    justify-content: center;
    align-items: flex-start;
    justify-items: center;
    align-content: stretch;
    @media screen and (max-width: 425px) {
        display: flex;
        flex-direction: column;
        column-gap: 1rem;
    }
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 1rem 0;
    align-items: center;
    column-gap: 1.5rem;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        column-gap: 1rem;
    }
`;

const Left = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
`;

const Rigth = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;
