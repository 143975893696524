import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    column-gap: 1.5rem;
`;

export const RowWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media (max-width: 900px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    text-align: center;
`;
