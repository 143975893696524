import React from 'react';
import { TextField } from '@material-ui/core';
import { SearchRounded } from '@material-ui/icons';
import Text from '../../../shared/common/Text';
import Wrapper from '../../../shared/common/Wrapper';
import { CenterSearchMember, ContainerGroupsPage } from '../styled-components/groups.styled.components';

const SearchMember = props => {
    const { search, handleSearch } = props;

    // RETURN
    return (
        <ContainerGroupsPage>
            <Text fontSize="1rem" fontWeight="700" color="primary">
                Integrantes
            </Text>
            <Wrapper>
                <CenterSearchMember>
                    <TextField
                        value={search}
                        onChange={handleSearch}
                        type="text"
                        placeholder="Buscar integrantes"
                        InputProps={{
                            endAdornment: <SearchRounded color="secondary" />,
                        }}
                        variant="outlined"
                        style={{ width: '100%' }}
                    />
                </CenterSearchMember>
            </Wrapper>
        </ContainerGroupsPage>
    );
};

export default SearchMember;
