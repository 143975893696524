import styled from 'styled-components';
import useStats from '../../hooks/useStats';
import StatsSection from './StatsSection';
import StatsSkeletonSection from './StatsSkeletonSection';

const Stats = () => {
    const { sections, loadingUsers, loadingGroups, tabs, tab: tabSelected, setTab } = useStats();

    return (
        <Container>
            <ButtonsContainer>
                {tabs.map((tabName, index) => (
                    <Button key={index} active={index === tabSelected} onClick={() => setTab(index)}>
                        {tabName}
                    </Button>
                ))}
            </ButtonsContainer>
            <InfoContainer>
                {loadingUsers || loadingGroups ? (
                    <LoadingContainer>
                        <StatsSkeletonSection />
                        <DividerSklt />
                        <StatsSkeletonSection />
                        <DividerSklt />
                        <StatsSkeletonSection />
                    </LoadingContainer>
                ) : (
                    <>
                        {sections[tabSelected].map((section, index) => {
                            if (index !== 0) {
                                return (
                                    <>
                                        <Divider />
                                        <StatsSection data={section} key={index} />
                                    </>
                                );
                            } else {
                                return <StatsSection data={section} key={index} />;
                            }
                        })}
                    </>
                )}
            </InfoContainer>
        </Container>
    );
};

export default Stats;

const Container = styled.div`
    width: 100%;
    height: 100%;
    max-height: 220px;
    min-height: 220px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const LoadingContainer = styled.div`
    width: calc(100% - 2rem);
    height: 100%;
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 1rem;
`;

const InfoContainer = styled.div`
    height: 100%;
    max-height: 160px;
    background: rgb(144, 23, 18);
    background: linear-gradient(180deg, rgba(144, 23, 18, 1) 0%, rgba(194, 45, 17, 1) 100%);
    border-radius: 20px;
    color: var(--secondary-one);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
`;

const Button = styled.button`
    height: 40px;
    border-radius: 30px;
    background-color: ${props => (props.active ? ' var(--primary-one)' : '#c4c4c4')};
    box-shadow: ${props => props.active && '0px 4px 4px 0px #00000040'};
    color: var(--secondary-one);
    font-size: 16px;
    font-weight: 600 !important;
    padding: 0 2rem;
`;

const Divider = styled.div`
    height: 50%;
    width: 1px;
    background-color: var(--secondary-one);
`;

const DividerSklt = styled.div`
    height: 50%;
    width: 1px;
    background-color: #535353;
`;
