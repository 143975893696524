import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { Icon } from '@iconify/react';
import TicketForm from './TicketForm';
import styled from 'styled-components';

const TicketPopUp = () => {
    const [open, setOpen] = useState(false);

    const bgcolor = `var(--primary-one)`;

    return (
        <WrapperTicketPopUp>
            <IconWrapper backgroundcolor={bgcolor} onClick={() => setOpen(true)}>
                <Icon icon="icon-park-outline:message-emoji" width="1.6em" height="1.6em" />
            </IconWrapper>
            <ModalWrapper open={open}>
                <TicketForm
                    open={open}
                    setOpen={setOpen}
                    proyectId={63}
                    title="Centro de ayuda ADEN"
                    subtitle="Completa el siguiente formulario y te responderemos a la brevedad"
                    primary={bgcolor}
                />
            </ModalWrapper>
        </WrapperTicketPopUp>
    );
};

export default TicketPopUp;

const WrapperTicketPopUp = styled.div`
    position: fixed;
    width: 80px;
    height: 80px;
    right: 1.5rem;
    bottom: 2rem;
    z-index: 10;
`;

const IconWrapper = styled(IconButton)`
    position: fixed;
    width: 80px;
    height: 80px;
    background-color: ${props => `${props.backgroundcolor}!important`};
    color: #fff !important;
    right: 1.5rem;
    bottom: 2rem;
    z-index: 100;
    &:hover {
        background-color: ${props => `${props.backgroundcolor}!important`};
        box-shadow: 0px 12px 24px -15px #0000001a, 0px 0px 10px -6px #00000040;
        transform: scale(1.2);
        transition: all 0.3s ease-in-out;
    }
    @media (width < 768px) {
        width: 50px;
        height: 50px;
    }
`;

const ModalWrapper = styled.div`
    height: 75vh;
    width: calc(500px - 2rem);
    background-color: #fff;
    position: fixed;
    right: ${props => (props.open ? '1.5rem' : '-40rem')};
    bottom: 2rem;
    z-index: 999;
    transition: all 0.5s ease;
    padding: 2rem 1rem 1rem 1rem;
    box-shadow: 0px 12px 24px -15px #0000001a, 0px 0px 10px -6px #00000040;
    border-radius: 20px;
    overflow-y: auto;

    @media (width < 768px) {
        width: 400px;
        right: ${props => (props.open ? '1.5rem' : '-50rem')};
        overflow-x: hidden;
    }
`;
