import { Skeleton } from '@mui/material';
import styled from 'styled-components';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';

const CourseCardSkeleton = props => {
    return (
        <CourseCardContainer>
            <ImgWrapper>
                <SkeletonImage variant="rectangular" animation="wave" width="100%" height={110} />
            </ImgWrapper>
            <Body>
                <DateWrapper>
                    <SkeletonStyled animation="wave" height={30} width="40%" />
                </DateWrapper>
                <SkeletonStyled animation="wave" variant="text" height={50} />
                <SkeletonStyled animation="wave" variant="text" height={25} width="100%" />
            </Body>
            <Footer>
                <BottomFooterWrapper>
                    <MoreVertRoundedIcon id="basic-button" />
                    <SkeletonStyled animation="wave" variant="rectangular" height={40} width="70%" />
                </BottomFooterWrapper>
            </Footer>
        </CourseCardContainer>
    );
};

export default CourseCardSkeleton;

const CourseCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
    min-height: 380px;
    width: 100%;
    border-radius: 20px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    padding: 1rem;
    height: 100%;
`;

const ImgWrapper = styled.div`
    position: relative;
    border-radius: 20px;
`;

const DateWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    color: #b7b7b7;
    border-radius: 100px;
`;

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    padding: 1.5rem;
    margin-top: auto;
    border-top: 1px solid #e8e8e8;
`;

const BottomFooterWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    svg {
        fill: var(--secondary-four);
    }
`;

const SkeletonStyled = styled(Skeleton)`
    border-radius: 20px;
`;

const SkeletonImage = styled(Skeleton)`
    border-radius: 20px 20px 0 0;
`;
