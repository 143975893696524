import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import styled from 'styled-components';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import InputIcon from '../../shared/common/InputIcon';
import NoResultsFound from '../../shared/common/NoResultsFound';
import ActionButton from '../blog/ActionButton';
import { getAllCustomOA } from '../../redux/api/content-creation.api';
import DefaultSorter from '../../shared/common/DefaultSorter';
import CustomCourseAlabCard from './CustomCourseAlabCard';
import { ModalProvider } from '../../hooks/ModalContext';
import DefaultStateFilter from '../filters/DefaultStateFilter';
import CourseCardSkeleton from '../content/CourseCardSkeleton';

const TabCourses = props => {
    const { user } = props;
    let history = useHistory();
    const estados = [
        { id: 0, name: 'Todos', value: 'Todos' },
        { id: 1, name: 'Publicados', value: 'Publicados' },
        { id: 2, name: 'Borradores', value: 'Borradores' },
    ];

    // STATE
    const [search, setSearch] = useState('');
    const [courses, setCourses] = useState([]);
    const [coursesFiltered, setCoursesFiltered] = useState([]);
    const [currentState, setCurrentState] = useState({ name: 'Todos', value: 'Todos' });
    const [order, setOrder] = useState('date-desc');
    const [changeState, setChangeState] = useState(false);
    const [loading, setLoading] = useState(true);

    // EFFECTS
    useEffect(() => {
        if (courses.length === 0) {
            getCoursesByOndemandId(user.id);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (search.length !== 0) {
            searchcourses(courses, search);
        } else {
            getCoursesByOndemandId(user.id);
        }
        // eslint-disable-next-line
    }, [search]);

    useEffect(() => {
        if (order === 'date-asc') {
            sortByDateAsc();
        } else if (order === 'date-desc') {
            sortByDateDes();
        }
        // eslint-disable-next-line
    }, [order]);

    useEffect(() => {
        if (currentState.name === 'Todos') {
            // getCoursesByOndemandId(user.id);
            setCoursesFiltered(courses);
        }
        if (currentState.name === 'Publicados') {
            setCoursesFiltered(courses.filter(value => value.published === true));
        }
        if (currentState.name === 'Borradores') {
            setCoursesFiltered(courses.filter(value => value.published === false));
        }
        setChangeState(true);
        setSearch('');
        // eslint-disable-next-line
    }, [currentState]);

    useEffect(() => {
        setTimeout(() => {
            setChangeState(false);
        }, 200);
        // eslint-disable-next-line
    }, [changeState]);

    // FUNCTIONS
    const getCoursesByOndemandId = async id => {
        let req = await getAllCustomOA(id);
        if (req.error) {
            alert('Ocurrió un error');
            setLoading(false);
        } else {
            setLoading(false);
            setCourses(req);
            setCoursesFiltered(req);
        }
    };

    const handleSearch = e => {
        setSearch(e.target.value);
        // setCurrentState(null);
    };

    const searchcourses = (array, search) => {
        let newArray = [];
        array.forEach(item => {
            if (item.nombre_publicacion?.toLowerCase().includes(search.toLowerCase())) {
                newArray.push(item);
            }
        });
        setCoursesFiltered(newArray.length === 0 ? [] : newArray);
    };

    //cuando exista el EP ordenar por fecha de creción o actualizacion del curso
    const sortByDateDes = () => {
        //más reciente
        setCoursesFiltered(
            courses?.sort((a, b) => (!!b.fecha_creacion && !!a.fecha_creacion ? new Date(b.fecha_creacion) - new Date(a.fecha_creacion) : null)),
        );
        setChangeState(true);
        setSearch('');
        // setCurrentState(null);
    };

    const sortByDateAsc = () => {
        //más antiguo
        setCoursesFiltered(
            courses?.sort((a, b) => (!!b.fecha_creacion && !!a.fecha_creacion ? new Date(a.fecha_creacion) - new Date(b.fecha_creacion) : null)),
        );
        setChangeState(true);
        setSearch('');
        // setCurrentState(null);
    };

    const onOrdersChange = newOrders => {
        setOrder(newOrders);
    };

    //Filtro DefaultStateFilter
    const handleCurrentState = newValue => {
        console.log('newValue', newValue);
        const estadoEncontrado = estados.find(estado => estado.name === newValue);
        if (estadoEncontrado) {
            setCurrentState(estadoEncontrado);
        }
    };

    const deleted = () => {
        history.push(`/content?tab=cursos_empresa`);
    };

    const publishedStateChanged = () => {
        // console.log('se solicito una publicacion/des');
        history.push(`/content?tab=cursos_empresa`);
    };

    //RETURN
    return (
        <>
            <HeaderWrapper>
                <Left>
                    <InputIcon value={search} onChange={handleSearch} />
                    <DefaultStateFilter currentState={currentState} handleCurrentState={handleCurrentState} estados={estados} />
                </Left>
                <Rigth>
                    <DefaultSorter onOrdersChange={onOrdersChange} order={order} />
                    <ActionButton
                        title="Añadir Curso"
                        action="/new-custom-course"
                        icon={<AddRoundedIcon />}
                        bgc="var(--primary-one)"
                        textColor="#ffff"
                    />
                </Rigth>
            </HeaderWrapper>
            {loading ? (
                <GridValues>
                    <CourseCardSkeleton />
                    <CourseCardSkeleton />
                    <CourseCardSkeleton />
                    <CourseCardSkeleton />
                    <CourseCardSkeleton />
                    <CourseCardSkeleton />
                </GridValues>
            ) : coursesFiltered?.length >= 0 ? (
                <GridValues>
                    {coursesFiltered?.map(value => (
                        <ModalProvider key={value.id}>
                            <CustomCourseAlabCard course={value} key={value.id} deleted={deleted} publishedStateChanged={publishedStateChanged} />
                        </ModalProvider>
                    ))}
                </GridValues>
            ) : search.length === 0 && currentState === 'Todos' ? (
                <NoResultsFound message="No existen aún cursos creados por la empresa, añade uno." />
            ) : (
                coursesFiltered?.length === 0 && <NoResultsFound message="No se encuentran resultados para tu búsqueda" />
            )}
        </>
    );
};

export default TabCourses;

const GridValues = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    height: auto;
    grid-gap: 1.5rem;
    justify-content: center;
    align-items: flex-start;
    justify-items: center;
    align-content: stretch;
    @media screen and (max-width: 425px) {
        display: flex;
        flex-direction: column;
        column-gap: 1rem;
    }
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 1rem 0;
    align-items: center;
    column-gap: 1.5rem;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        column-gap: 1rem;
    }
`;

const Left = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
`;

const Rigth = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;
