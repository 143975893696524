import { Skeleton } from '@mui/material';
import styled from 'styled-components';

const StatsSkeletonSection = () => {
    return (
        <Wrapper>
            <div>
                <Skeleton animation="wave" variant="circular" width={50} height={50} />
            </div>
            <div>
                <Skeleton animation="wave" variant="text" width={70} height={40} />
                <Skeleton animation="wave" variant="text" width={70} height={40} />
            </div>
        </Wrapper>
    );
};

export default StatsSkeletonSection;

const Wrapper = styled.div`
    border-radius: 10px;
    width: calc(100% - 2rem);
    height: 80%;
    gap: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0 1rem;
`;
