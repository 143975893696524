import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { contentActions } from '../../../../redux/actions';
import ContentCardSmall from './ContentCardSmall';
import { ModalProvider } from '../../../../hooks/ModalContext';
import Text from '../../../../shared/common/Text';
import ContentSkeletonCardSmall from './ContentSkeletonCardSmall';

const NewContent = () => {
    // REDUX
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const content = useSelector(state => state.content.content);
    const loadingContent = useSelector(state => state.content.loadingContent);

    // EFFECTS
    useEffect(() => {
        if (content === null) {
            dispatch(contentActions.getContentRequest(user.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content]);

    // RETURN
    return (
        <Wrapper>
            <h2>¡Nuevo contenido!</h2>
            <Container>
                <Text fontSize="14px" fontWeight="700" fontcolor="var(--primary-one)" component="h3">
                    Accede a los cursos agregados recientemente
                </Text>
                {content === null || loadingContent ? (
                    <BodyWrapper>
                        <ContentSkeletonCardSmall />
                        <ContentSkeletonCardSmall />
                        <ContentSkeletonCardSmall />
                        <ContentSkeletonCardSmall />
                    </BodyWrapper>
                ) : (
                    <BodyWrapper>
                        {Array.isArray(content)
                            ? content.slice(content.length - 4).map(item => (
                                  <ModalProvider key={item.id}>
                                      <ContentCardSmall course={item} key={item.id} />
                                  </ModalProvider>
                              ))
                            : content}
                    </BodyWrapper>
                )}
            </Container>
        </Wrapper>
    );
};

export default NewContent;

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    gap: 1rem;

    h2 {
        color: var(--primary-one);
        font-size: 20px;
        font-weight: 700;
    }
`;

const Container = styled.div`
    height: 100%;
    max-height: 545px;
    display: flex;
    flex-direction: column;
    background-color: var(--secondary-one);
    border: 3px solid var(--primary-one);
    border-radius: 30px;
    padding: 2rem 1rem 2rem 2rem;
    margin-bottom: 0.8rem;
    gap: 1rem;
`;

const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: #d4d4d4 transparent;
    padding-right: 4px;
`;
