import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

// Components
import InputIcon from '../../../../shared/common/InputIcon';
import FilterResource from './FilterResource';
import AssignmentResourceButton from '../assignment/AssignmentResourceButton';
import TableResource from '../table/TableResource';

// Material UI
import AddRoundedIcon from '@mui/icons-material/AddRounded';

// Services
import { getResourcesAdministrator } from '../../services/resources.services';
import { resourcesAdapter } from '../../adapters/resources.adapter';

// Styles
import {
    FirstRow,
    SecondRow,
    ThirdRow,
    PaginationContainer,
    WrapperButtons,
    ResourceCreate,
    ResourcesNotFound,
} from '../../styled-components/resources.styled.components';
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react/dist/iconify.js';
import TableSkeleton from '../table/TableSkeleton';

const TabResources = props => {
    const { type = 'aden' } = props;

    let history = useHistory();

    const [allResources, setAllResources] = useState([]);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState({ name: 'Audiovisuales', value: 'audiovisual' });
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [selectedResources, setSelectedResources] = useState([]);

    const ondemand_id = useSelector(state => state?.user?.user?.id);

    const PAGE_SIZE = 10;

    const filteredResources = allResources.filter(resource => {
        if (search === '') return true;
        return resource.title?.toLowerCase().includes(search.toLowerCase());
    });

    const paginatedResources = filteredResources.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);

    useEffect(() => {
        const totalPages = Math.ceil((search === '' ? allResources?.length : filteredResources?.length) / PAGE_SIZE);
        setTotalPages(totalPages);
    }, [allResources, filteredResources, search]);

    useEffect(() => {
        setCurrentPage(1);
        handleResources();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, type]);

    const handleResources = async () => {
        setLoading(true);
        let req = await getResourcesAdministrator(ondemand_id, filter.value);
        if (!!req) {
            type === 'aden' ? setAllResources(resourcesAdapter(req?.aden_resources)) : setAllResources(resourcesAdapter(req?.company_resources));
            setLoading(false);
        }
    };

    function handleSearch(e) {
        setSearch(e.target.value);
    }

    //filtro por tipo de recurso
    function handleFilter(e) {
        setFilter(e);
    }

    const handlePagination = page => {
        setCurrentPage(page);
    };

    return (
        <>
            <FirstRow>
                <InputIcon value={search} onChange={handleSearch} />
                <WrapperButtons>
                    {type === 'company' && (
                        <ResourceCreate onClick={() => history.push('/new-custom-resource')}>
                            <AddRoundedIcon />
                            Crear nuevo recurso
                        </ResourceCreate>
                    )}
                    <AssignmentResourceButton selectedResources={selectedResources} />
                </WrapperButtons>
            </FirstRow>
            <SecondRow>
                <FilterResource filter={filter} handleFilter={handleFilter} />
            </SecondRow>
            <ThirdRow>
                {loading ? (
                    <TableSkeleton />
                ) : (
                    <>
                        {paginatedResources?.length === 0 ? (
                            <ResourcesNotFound>No se encontraron recursos de este tipo</ResourcesNotFound>
                        ) : (
                            <TableResource
                                setSelectedResources={setSelectedResources}
                                selectedResources={selectedResources}
                                resources={paginatedResources}
                                type={type}
                            />
                        )}
                    </>
                )}
            </ThirdRow>
            <PaginationContainer>
                <button
                    onClick={() => {
                        handlePagination(1);
                    }}>
                    <Icon icon="lucide:arrow-left-to-line" width="1.2em" height="1.2em" />
                </button>
                <button
                    onClick={() => {
                        if (currentPage !== 1) {
                            handlePagination(currentPage - 1);
                        }
                    }}>
                    <Icon icon="heroicons-outline:arrow-left" width="1.2em" height="1.2em" />
                </button>
                <p>
                    Página <b>{currentPage}</b> de {totalPages}
                </p>
                <button
                    onClick={() => {
                        if (currentPage !== totalPages) {
                            handlePagination(currentPage + 1);
                        }
                    }}>
                    <Icon icon="heroicons-outline:arrow-right" width="1.2em" height="1.2em" />
                </button>
                <button
                    onClick={() => {
                        handlePagination(totalPages);
                    }}>
                    <Icon icon="lucide:arrow-right-to-line" width="1.2em" height="1.2em" />
                </button>
            </PaginationContainer>
        </>
    );
};

export default TabResources;
