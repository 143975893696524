import React from 'react';
import { Skeleton } from '@mui/material';
import styled from 'styled-components';

const TableSkeleton = () => {
    const headerColumns = 3;
    const bodyColumns = 5;
    const rows = 1;

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {Array.from({ length: headerColumns }).map((_, index) => (
                            <TableCell key={index}>
                                <Skeleton variant="text" height={50} />
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.from({ length: rows }).map((_, rowIndex) => (
                        <TableBodyRow key={rowIndex}>
                            {Array.from({ length: bodyColumns }).map((_, colIndex) => (
                                <TableCell key={colIndex}>
                                    <Skeleton variant="rectangular" height={60} />
                                </TableCell>
                            ))}
                        </TableBodyRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const TableContainer = styled.div`
    width: 100%;
    overflow-x: auto;
`;

const Table = styled.div`
    width: 100%;
    border-collapse: collapse;
`;

const TableHead = styled.div`
    background-color: #f5f5f5;
`;

const TableBody = styled.div``;

const TableRow = styled.div`
    display: flex;
`;

const TableCell = styled.div`
    flex: 1;
    padding: 16px;
`;

const TableBodyRow = styled.div`
    display: grid;
    column-gap: 1rem;
    align-items: center;
`;

export default TableSkeleton;
