import { Skeleton } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const ContentSkeletonCardSmall = () => {
    return (
        <Wrapper>
            <NameWrapper>
                <Skeleton animation="wave" variant="text" width={250} height={30} />
            </NameWrapper>
            <ActionsWrapper>
                <Skeleton animation="wave" variant="circular" width={30} height={30} />{' '}
                <Skeleton animation="wave" variant="circular" width={30} height={30} />{' '}
                <Skeleton animation="wave" variant="circular" width={30} height={30} />
            </ActionsWrapper>
        </Wrapper>
    );
};

export default ContentSkeletonCardSmall;

const Wrapper = styled.div`
    border-radius: 10px;
    width: calc(100% - 2rem);
    height: 60px;
    gap: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 1rem;
    border: 1px solid #c4c4c4;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
`;

const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: end;
    gap: 1rem;
    width: 40%;
`;

const NameWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 60%;
`;
