import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { participantActions } from "../../../redux/actions";
import { PersonAddRounded } from "@material-ui/icons";
import ModalForm from "../../../shared/common/ModalForm";
import FormAddUser from "./FormAddUser";
import AddUserSuccess from "./AddUserSuccess";
import { ContainerAddUser } from "../styled-components/addUser/addUsers.styled.components";
import { ButtonFilledDark } from "../../../styled-components/buttons.styled.components";

const AddUser = () => {
	// REDUX
	const dispatch = useDispatch();

	const newParticipant = useSelector(
		(state) => state.participant.newParticipant
	);

	// STATE
	const [isOpen, setIsOpen] = useState(false);

	// FUNCTIONS
	function handleOpen() {
		setIsOpen(true);
	}

	function handleClose() {
		setIsOpen(false);
		dispatch(participantActions.confirmCreateParticipant());
	}

	// RETURN
	return (
		<>
			<ButtonFilledDark
				variant="contained"
				color="primary"
				size="small"
				startIcon={<PersonAddRounded />}
				onClick={handleOpen}
			>
				Añadir usuario
			</ButtonFilledDark>
			<ModalForm isOpen={isOpen} handleClose={handleClose} banner={null}>
				<ContainerAddUser>
					{newParticipant ? (
						<AddUserSuccess handleClose={handleClose} />
					) : (
						<FormAddUser />
					)}
				</ContainerAddUser>
			</ModalForm>
		</>
	);
};

export default AddUser;