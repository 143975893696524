import React from 'react';
import Wrapper from '../../../shared/common/Wrapper';
import { ContainerSkt, HeaderWrapperSkt, SkeletonStyled } from '../styled-components/groups.styled.components';

const GroupsSkt = () => {
    return (
        <ContainerSkt>
            <HeaderWrapperSkt>
                <Wrapper width="75%">
                    <SkeletonStyled variant="rect" animation="wave" width="100%" height={65} />
                </Wrapper>
                <Wrapper width="25%">
                    <SkeletonStyled variant="rect" animation="wave" width="100%" height={65} />
                </Wrapper>
            </HeaderWrapperSkt>
            <Wrapper width="100%">
                <SkeletonStyled variant="rect" animation="wave" width="100%" height={530} />
            </Wrapper>
        </ContainerSkt>
    );
};

export default GroupsSkt;

