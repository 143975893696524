import React from 'react';
import CsvCreator from 'react-csv-creator';
import { GetAppRounded } from '@material-ui/icons';
import { ButtonOutlinedDark } from '../../../styled-components/buttons.styled.components';

const ExportUsers = props => {
    const { participants } = props;

    const headers = [
        {
            id: 'nombre',
            display: 'Nombre',
        },
        {
            id: 'email',
            display: 'Correo electrónico',
        },
        {
            id: 'fecha_alta',
            display: 'Fecha de alta',
        },
        {
            id: 'ultimo_login',
            display: 'Ultimo acceso',
        },
        {
            id: 'estado',
            display: 'Estado',
        },
    ];

    // console.log('participants', participants)
    return (
        <>
            <CsvCreator filename="participantes_csv" headers={headers} rows={participants}>
                <ButtonOutlinedDark variant="outlined" color="primary" size="small" startIcon={<GetAppRounded />}>
                    Exportar
                </ButtonOutlinedDark>
            </CsvCreator>
        </>
    );
};

export default ExportUsers;
