import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { participantActions } from '../../redux/actions';
import RemoveUsers from '../content/RemoveUsers';
import ViewSubject from './ViewSubject';
import EnrolledParticipants from '../content/EnrolledParticipants';
import MessageModal from '../../shared/common/MessageModal';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { Menu, MenuItem } from '@material-ui/core';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import GroupRemoveRoundedIcon from '@mui/icons-material/GroupRemoveRounded';
import { useModal } from '../../hooks/ModalContext';

const SubjectAlabCard = props => {
    const { subject } = props;

    // REDUX
    const dispatch = useDispatch();

    //STATES
    const [contentMessage, setContentMessage] = useState(null);
    const [assignSuccess, setAssignSuccess] = useState(false);
    const [removeSuccess, setRemoveSuccess] = useState(false);

    // FUNCTIONS
    //menu izq desplegable
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    //USEMODAL
    const { state, openModal, closeModal } = useModal();
    const handleOpenModal = modalType => {
        openModal(modalType);
    };
    const handleCloseModal = () => {
        closeModal();
    };

    //EFFECTS
    //matriculacion exitosa
    useEffect(() => {
        if (!!assignSuccess) {
            handleOpenModal('entendido');
            setContentMessage({
                message: '¡Matriculación exitosa!',
                image: '/images/matriculacion_usuario.svg',
                subtitle: 'La Ruta Formativa fue asignada a los alumnos seleccionados de manera correcta.',
            });

            setTimeout(() => {
                dispatch(participantActions.confirmAssignSubject());
                setAssignSuccess(false);
            }, 500);
        }
        //eslint-disable-next-line
    }, [assignSuccess, dispatch]);

    //desmatriculacion exitosa
    useEffect(() => {
        if (!!removeSuccess) {
            handleOpenModal('entendido');
            setContentMessage({
                message: '¡Desmatriculación exitosa!',
                image: '/images/desmatriculacion_usuario.svg',
                subtitle: 'La Ruta Formativa fue desasignada a los alumnos seleccionados de manera correcta.',
            });

            setTimeout(() => {
                setRemoveSuccess(false);
            }, 500);
        }
        //eslint-disable-next-line
    }, [removeSuccess, dispatch]);

    return (
        <>
            <CardFake>
                <Header image={subject?.img}>{subject?.tag_id ? <TagSpan>{subject.tag_id[0]}</TagSpan> : null}</Header>
                <Body>
                    <h2>{subject?.name}</h2>
                    <Span>
                        {subject.description !== '' &&
                        subject.description !== undefined &&
                        subject.description !== null &&
                        subject.description !== false
                            ? subject.description
                            : null}
                    </Span>
                </Body>
                <Footer>
                    <>
                        <MoreVertRoundedIcon
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        />
                        <MenuFake
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleCloseMenu}
                            MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                            <MenuItem onClick={() => handleOpenModal('previsualizar')}>
                                <RemoveRedEyeIcon />
                                Vista previa
                            </MenuItem>
                            <MenuItem onClick={() => handleOpenModal('desmatricular')}>
                                <GroupRemoveRoundedIcon />
                                Desmatricular alumnos
                            </MenuItem>
                        </MenuFake>
                    </>
                    <AddedButton variant="contained" color="primary" onClick={() => handleOpenModal('matricular')}>
                        <span>Matricular alumnos</span>
                    </AddedButton>
                </Footer>
            </CardFake>

            {state.isOpen &&
                state.modalType === 'entendido' && ( //mensaje entendido
                    <MessageModal
                        isOpen={state.isOpen}
                        handleClose={handleCloseModal}
                        message={contentMessage?.message}
                        image={contentMessage?.image}
                        subtitle={contentMessage?.subtitle}
                    />
                )}

            {state.isOpen &&
                state.modalType === 'matricular' && ( //modal de matriculación
                    <EnrolledParticipants
                        content={subject}
                        type={'subject'}
                        openModal={state.isOpen}
                        closeModal={handleCloseModal}
                        success={() => setAssignSuccess(true)}
                    />
                )}

            {state.isOpen &&
                state.modalType === 'previsualizar' && ( //modal de previsualización
                    <ViewSubject subject={subject} openModal={state.isOpen} closeModal={handleCloseModal} />
                )}

            {state.isOpen &&
                state.modalType === 'desmatricular' && ( //modal de desmatriculación
                    <RemoveUsers
                        course={subject}
                        type={'subject'}
                        openModal={state.isOpen}
                        closeModal={handleCloseModal}
                        removeSucces={() => setRemoveSuccess(true)}
                    />
                )}
        </>
    );
};

export default SubjectAlabCard;

const CardFake = styled.div`
    min-width: 350px;
    height: 340px;
    padding: 0;
    max-width: 320px;
    border-radius: 20px;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    position: relative;
    background-color: #fafafa;

`;

const Header = styled.div`
    height: 100px;
    padding: 1.5rem;
    border-radius: 20px 20px 0 0;
    width: calc(100% - 3rem);
    background-image: url(${p => `${p.image}`});
    background-size: cover;
    background-position: center center;
    position: relative;
`;

const Body = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.8rem;
    width: 290px;
    overflow: hidden;
    white-space: nowrap;
    h2 {
        color: #222222;
        font-size: 1.2rem;
        text-overflow: ellipsis;
        padding: 0.5rem;
    }
`;

const Span = styled.span`
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 140%;
    color: #616161;
    display: flex;
    align-items: flex-end;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    text-overflow: ellipsis;

    svg {
        font-weight: 400;
        font-size: 1.5rem;
        color: #616161;
        opacity: 50%;
        padding: 0 1rem 0 0;
    }
`;

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    width: calc(100%-4rem);
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    height: calc(100%-2rem);
    gap: 0.8rem;
    position: absolute;
    bottom: 1%;
    left: 1%;
    right: 1%;

    svg {
        fill: var(--secondary-four);
        cursor: pointer;
    }
`;

const TagSpan = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1rem;
    gap: 10px;
    position: absolute;
    width: 50%;
    left: 0px;
    bottom: 0px;
    background: #ffffff;
    border-radius: 0px 10px 0px 0px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 1rem;
    text-align: right;
    color: #616161;
`;

const AddedButton = styled.button`
    background-color: var(--primary-one);
    border-radius: 3rem;
    padding: 0.5rem;
    gap: 1rem;
    border: none;
    width: calc(100% - 1rem);
    align-items: center;
    display: flex;
    justify-content: space-evenly;

    span {
        color: #fafafa !important;
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 0.8rem;
        line-height: 1.5rem;
    }

    :hover {
        background-color:var(--secondary-three);
    }
`;

const MenuFake = styled(Menu)`
    ul li span {
        color: var(--secondary-four);
        font-size: 0.8rem;
        font-weight: 400;
        font-family: 'Montserrat';
        font-style: normal;
    }

    .MuiList-root-MuiMenu-list {
        color: var(--secondary-four);
    }

    .MuiSvgIcon-root {
        fill: var(--secondary-four);
        margin-right: 1rem;
    }
    .MuiIconButton-colorPrimary:hover {
        background-color: transparent;
    }

    .MuiIconButton-root:hover {
        background-color: transparent;
    }

    .MuiButtonBase-root {
        width: 100%;
        justify-content: flex-start;
        color: #222222;
        font-size: 0.8rem;
        line-height: 1.5rem;
        font-weight: 500;
        font-family: 'Montserrat';
        font-style: normal;
    }
`;
