import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { groupActions } from '../../../redux/actions';
import FormEditGroup from './FormEditGroup';
import EditGroupSuccess from './EditGroupSuccess';
import ModalForm from '../../../shared/common/ModalForm';
import { EditRounded } from '@material-ui/icons';
import { ButtonFilledDark } from '../../../styled-components/buttons.styled.components';
import { ContainerFormGroup } from '../styled-components/groups.styled.components';

const EditGroup = () => {
    const { id } = useParams();

    // REDUX
    const dispatch = useDispatch();
    const editGroup = useSelector(state => state.group.editGroup);
    const editedConfirm = useSelector(state => state.group.editGroup);
    const groups = useSelector(state => state.group.groups);

    // STATE
    const [isOpen, setIsOpen] = useState(false);
    const [currentGroup, setCurrentGroup] = useState(null);

    // FUNCTIONS
    function handleOpen() {
        setIsOpen(true);
        dispatch(groupActions.confirmEditGroup());
    }

    function handleClose() {
        setIsOpen(false);
    }

    const getGroups = () => {
        if (groups === null) {
            dispatch(groupActions.getGroupsRequest({ id }));
        }
    };

    // EFFECTS
    useEffect(() => {
        getGroups();
    }, [groups, id, editGroup]);

    useEffect(() => {
        if (id !== null && groups !== null) {
            groups.forEach(group => {
                if (group.id === parseInt(id, 10)) {
                    setCurrentGroup(group);
                }
            });
        }
    }, [id, groups]);

    // RETURN
    return (
        <>
            <ButtonFilledDark 
            variant="contained" 
            color="primary"
            size="small" 
            startIcon={<EditRounded />} 
            onClick={handleOpen}>
                Editar
            </ButtonFilledDark>
            <ModalForm isOpen={isOpen} handleClose={handleClose} title={editGroup ? '' : 'Editar grupo'}>
                <ContainerFormGroup formGroup={editGroup}>
                    {editGroup === false && editedConfirm === false ? (
                        <FormEditGroup group={currentGroup} />
                    ) : (
                        <EditGroupSuccess handleClose={handleClose} />
                    )}
                </ContainerFormGroup>
            </ModalForm>
        </>
    );
};

export default EditGroup;

