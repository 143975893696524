import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    padding: 0 1rem;
    height: 100%;
    width: max-content;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    padding: 0 1rem;
    height: 100%;
    width: max-content;
    align-self: ${p => (p.alignSelf ? p.alignSelf : 'flex-end')};;
`;

export const ButtonFilledDark = styled(Button)`
    justify-content: center;
    padding: 0.25rem 1rem;
    &.MuiButton-containedSizeSmall {
        border-radius: 100px;
    }
    span,
    svg {
        font-size: 0.8rem;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        padding: 0.15rem;
    }
`;

export const ButtonOutlinedDark = styled(Button)`
    justify-content: center;
    padding: 0.25rem 1rem;
    &.MuiButton-outlinedSizeSmall {
        border-radius: 100px;
    }
    span,
    svg {
        font-size: 0.8rem;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        padding: 0.15rem;
    }
`;

