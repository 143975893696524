import React from 'react';
import { GetAppRounded } from '@material-ui/icons';
import CsvCreator from 'react-csv-creator';
import { ButtonOutlinedDark } from '../../../styled-components/buttons.styled.components';

const ExportGroups = props => {
    const { groups } = props;

    const headers = [
        {
            id: 'nombre',
            display: 'NOMBRE GRUPO',
        },
        {
            id: 'cant_integrantes',
            display: 'CANTIDAD DE INTEGRANTES',
        },
    ];

    const formatedGroups = groups?.map(group => {
        return {
            nombre: group?.nombre,
            cant_integrantes: group?.participantes?.length,
        };
    });

    return (
        <>
            <CsvCreator filename="grupos_csv" headers={headers} rows={formatedGroups}>
                <ButtonOutlinedDark variant="outlined" color="primary" size="small" startIcon={<GetAppRounded />}>
                    Exportar
                </ButtonOutlinedDark>
            </CsvCreator>
        </>
    );
};

export default ExportGroups;
