import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subjectActions } from '../../redux/actions';
import styled from 'styled-components';
import InputIcon from '../../shared/common/InputIcon';
import DefaultSorter from '../../shared/common/DefaultSorter';
import NoResultsFound from '../../shared/common/NoResultsFound';
import SubjectAlabCard from '../subjects/SubjectAlabCard';
import { ModalProvider } from '../../hooks/ModalContext';
import SubjectsSkt from '../subjects/SubjectsSkt';
// import DefaultStateFilter from '../filters/DefaultStateFilter';

const TabAlabRoutes = props => {
    const { user } = props;
    // const estados = [
    //     { id: 0, name: 'Todas', value: 'Todas' },
    //     { id: 1, name: 'Asignadas', value: 'Asignadas' },
    //     { id: 2, name: 'No Asignadas', value: 'No Asignadas' },
    // ];

    //REDUX
    const dispatch = useDispatch();
    const subject = useSelector(state => state.subject.subject);
    const loadingSubject = useSelector(state => state.subject.loadingSubject);

    // STATE
    const [search, setSearch] = useState('');
    const [subjects, setSubjects] = useState(subject);
    const [subjectsFiltered, setSubjectsFiltered] = useState([]);
    // const [currentState, setCurrentState] = useState( { id: 0, name: 'Todas', value: 'Todas' });
    const [order, setOrder] = useState('date-desc');
    const [changeState, setChangeState] = useState(false);

    // EFFECTS
    useEffect(() => {
        if (subject === null) {
            dispatch(subjectActions.getSubjectRequest(user.id));
            // console.log('subject', subject);
            if (subject !== null) {
                setSubjects(subject);
                setSubjectsFiltered(subject);
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (subject !== null) {
            // console.log('subject', subject);
            setSubjects(subject);
            setSubjectsFiltered(subject);
        }
        // eslint-disable-next-line
    }, [subject]);

    useEffect(() => {
        if (search.length !== 0) {
            searchsubjects(subjects, search);
        } else {
            setSubjects(subject);
            setSubjectsFiltered(subject);
        }
        // eslint-disable-next-line
    }, [search]);

    useEffect(() => {
        if (order === 'date-asc') {
            sortByIdAsc();
        } else if (order === 'date-desc') {
            sortByIdDes();
        }
        // eslint-disable-next-line
    }, [order]);

    // useEffect(() => {
    //     if (currentState.name === 'Todas') {
    //         setSubjects(subject);
    //         setSubjectsFiltered(subject);
    //     }
    //     if (currentState.name === 'Asignadas') {
    //         setSubjectsFiltered(subjects?.filter(value => value.total_enrolled > 0));
    //     }
    //     if (currentState.name === 'No Asignadas') {
    //         // console.log('currentState', currentState);
    //         setSubjectsFiltered(subjects?.filter(value => value.total_enrolled === 0));
    //     }
    //     setChangeState(true);
    // }, [currentState]);

    useEffect(() => {
        setTimeout(() => {
            setChangeState(false);
        }, 200);
    }, [changeState]);

    // FUNCTIONS
    const handleSearch = e => {
        setSearch(e.target.value);
    };

    //Filtro DefaultStateFilter
    // const handleCurrentState = newValue => {
    //     console.log('newValue', newValue);
    //     const estadoEncontrado = estados.find(estado => estado.name === newValue);
    //     if (estadoEncontrado) {
    //         setCurrentState(estadoEncontrado);
    //     }
    // };

    const searchsubjects = (array, search) => {
        let newArray = [];
        array.forEach(item => {
            if (item.name?.toLowerCase().includes(search.toLowerCase())) {
                newArray.push(item);
            }
        });
        setSubjectsFiltered(newArray.length === 0 ? [] : newArray);
    };

    const sortByIdDes = () => {
        //más reciente
        setSubjectsFiltered(
            subjects?.sort((a, b) => {
                return b.id > a.id;
            }),
        );
        setChangeState(true);
    };

    const sortByIdAsc = () => {
        //más antiguo
        setSubjectsFiltered(
            subjects?.sort((a, b) => {
                return a.id > b.id;
            }),
        );
        setChangeState(true);
    };

    const onOrdersChange = newOrders => {
        setOrder(newOrders);
    };


    //RETURN
    if (!subjects || loadingSubject || subject === null) {
        return <SubjectsSkt />;
    }
    return (
        <>
            <HeaderWrapper>
                <Left>
                    <InputIcon value={search} onChange={handleSearch} />
                    {/* <DefaultStateFilter currentState={currentState} handleCurrentState={handleCurrentState} estados={estados} /> */}
                </Left>
                <Rigth>
                    <DefaultSorter onOrdersChange={onOrdersChange} order={order} />
                </Rigth>
            </HeaderWrapper>
            {subjectsFiltered.length > 0 ? (
                <GridValues>
                    {subjectsFiltered.map(
                        value =>
                            value.id !== undefined && (
                                <ModalProvider key={value.id}>
                                    <SubjectAlabCard subject={value} key={value.id} artId={value.id} />
                                </ModalProvider>
                            ),
                    )}
                </GridValues>
            ) : search.length === 0 ? (
                // && currentState === 'Todas'
                <NoResultsFound message="No es posible acceder a Rutas Formativas, por favor comunicate con el administrador." />
            ) : (
                <NoResultsFound message="No se encuentran resultados para tu búsqueda" />
            )}
        </>
    );
};

export default TabAlabRoutes;
const GridValues = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    height: auto;
    grid-gap: 1.5rem;
    justify-content: center;
    align-items: flex-start;
    justify-items: center;
    align-content: stretch;
    @media screen and (max-width: 425px) {
        display: flex;
        flex-direction: column;
        column-gap: 1rem;
    }
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 1rem;
    align-items: center;
    column-gap: 1.5rem;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        column-gap: 1rem;
    }
`;

const Left = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem;
`;

const Rigth = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;
