import { MenuItem } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import styled from 'styled-components';

export const ContainerGroupsPage = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const HeaderWrapperGroupsPage = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    gap: 1.5rem;
    width: calc(100% - 2rem);
    @media (max-width: 800px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const CenterSearchMember = styled.div`
    display: flex;
    justify-content: center;
    padding: 1rem;
`;

export const ButtonsWrapperGroupsPage = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 1rem;
    height: calc(60px - 2rem);
    width: calc(max-content - 2rem);
    background-color: #f9f9f9;
    box-shadow: 0px 0px 9px -2px rgba(219, 219, 219, 0.75);
    -webkit-box-shadow: 0px 0px 9px -2px rgba(219, 219, 219, 0.75);
    -moz-box-shadow: 0px 0px 9px -2px rgba(219, 219, 219, 0.75);
    border-radius: 1rem;
`;

export const ImageStyle = styled.img`
    width: 250px;
    height: auto;
    margin-top: 1rem;
`;

export const TextMessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    text-align: center;
`;

export const ContainerFormGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${p => (p.formGroup ? 'center' : '')};
    row-gap: ${p => (p.formGroup ? '2rem' : '')};
    padding: 1.5rem;
    min-width: ${p => (p.formGroup ? '320px' : '768px')};
    max-width: ${p => (p.formGroup ? '320px' : '')};
`;

export const FormAddGroupContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: calc(100% - 2rem);
    padding: 0 0.5rem;
`;

export const FormAddGroupWrapper = styled.div`
    display: grid;
    columns: span 24;
    grid-template-columns: 3fr 1fr;
    align-items: center;
    justify-content: space-around;
    padding: 0 1rem;
`;
export const FormAddGroupWrapperCheck = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    vertical-align: center;
    border-radius: 100px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
    margin: 1.5rem 0.5rem 0rem 0rem;
    padding: 0.5rem;
`;

export const MenuItemStyle = styled(MenuItem)`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;

// Skeleton
export const ContainerSkt = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`;

export const HeaderWrapperSkt = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1.5rem;
`;

export const SkeletonStyled = styled(Skeleton)`
    border-radius: 1rem;
`;
