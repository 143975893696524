import axios from "axios";

export async function getContent(id) {
	try {
        const response = await axios.get(`${process.env.REACT_APP_SISAPI_ONDEMAND}/obj_aprendizaje_all/${id}`);
        return response;
    } catch (error) {
        return {
            error,
        };
    }
}


export function getCategories() {
	return axios.get();
}


