import React, { useState } from 'react';
import styled from 'styled-components';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Wrapper from './Wrapper';
import { SettingsRounded } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { getLocalUTC } from '../../helpers/time.helper';
import MoreMenu from '../../pages/Users/components/MoreMenu';
import TableMenu from '../../pages/Groups/components/TableMenu';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, headCells } = props;

    // FUNCTIONS
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    // RETURN
    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell key={headCell.id} padding="default" sortDirection={orderBy === headCell.id ? order : false}>
                        <FakeTableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                            {orderBy === headCell.id ? <SortHidden>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</SortHidden> : null}
                        </FakeTableSortLabel>
                    </TableCell>
                ))}
                <TableCell padding="default">
                    <SettingsRounded fontSize="small" />
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

const DefaultTable = props => {
    const { rows, headCells, type, group } = props;
    let history = useHistory();
    // STATE
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('nombre');
    const [page, setPage] = useState(0);

    // FUNCTIONS
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // const emptyRows = 50 - Math.min(50, rows?.length - page * 50);

    function pushToUser(id) {
        history.push(`/user/${id}`);
    }

    function pushToGroup(id) {
        history.push(`/group/${id}`);
    }

    // RETURN
    return (
        <Wrapper>
            <TableContainer>
                <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                    <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headCells={headCells} />
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                            .slice(page * 50, page * 50 + 50)
                            .map((row, index) => {
                                const utc = getLocalUTC();
                                const ultimo_acceso = row.ultimo_login 
                                ? (moment(new Date(row.ultimo_login?.replaceAll('-', '/') + `${utc} UTC`)).format('DD/MM/YY HH:mm'))
                                :(null);
                                const fecha_alta = row.fecha_alta  
                                ? ( moment(new Date(row.fecha_alta?.replaceAll('-', '/') + `${utc} UTC`)).format('DD/MM/YY HH:mm')) 
                                : null;

                                const labelId = `enhanced-table-checkbox-${index}`;
                                if (type === 'users') {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                            style={{
                                                cursor: 'pointer',
                                            }}>
                                            <TableCell component="th" id={labelId} scope="row" padding="default" onClick={() => pushToUser(row.participante_id)}> {row.nombre} </TableCell>
                                            <TableCell onClick={() => pushToUser(row.participante_id)}>{row.email}</TableCell>
                                            <TableCell onClick={() => pushToUser(row.participante_id)}>{fecha_alta || '-'}</TableCell>
                                            <TableCell onClick={() => pushToUser(row.participante_id)}>{ultimo_acceso || '-'}</TableCell>
                                            {/* <TableCell onClick={() => pushToUser(row.participante_id)}>
                                                {row.cursos} 
                                            </TableCell>  */}
                                            <TableCell onClick={() => pushToUser(row.participante_id)}>
                                                <SpanState background={row.estado}>{row.estado}</SpanState>
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    group!== true && <MoreMenu 
                                                    id={row.participante_id} 
                                                    repositorio_id={row.repositorio_id}
                                                    status={row.estado}
                                                    />
                                                }
                                            </TableCell>
                                        </TableRow>
                                    );
                                } else {
                                    return (
                                        <TableRow hover tabIndex={-1} key={index} style={{ cursor: 'pointer' }}>
                                            <TableCell 
                                            component="th" 
                                            id={labelId} 
                                            scope="row" 
                                            padding="default"
                                            onClick={()=>pushToGroup(row.id)}
                                            // onClick={() => <EditGroup />}
                                            >
                                                {row.nombre}
                                            </TableCell>
                                            {/* <TableCell>{row.fecha_creacion || '-'}</TableCell> */}
                                            <TableCell 
                                            onClick={()=>pushToGroup(row.id)}
                                            // onClick={() => <EditGroup />}
                                            >
                                                {row.participantes.length}
                                            </TableCell>
                                            {/* <TableCell>{row.ultimo_acceso || '-'}</TableCell>
                                            <TableCell>
                                                <SpanState background={row.estado ? row.estado.toLowerCase() : 'inactivo'}>
                                                    {row.estado || 'inactivo'}
                                                </SpanState>
                                            </TableCell> */}
                                            {/* <TableCell onClick={() => pushToGroup(row.id)}>
                                            {row.cursos}
                                            </TableCell>  */}
                                            <TableCell>
                                                <TableMenu id={row.id}/>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={rows.length}
                rowsPerPage={50}
                page={page}
                onChangePage={handleChangePage}
            />
        </Wrapper>
    );
};

export default DefaultTable;

const FakeTableSortLabel = styled(TableSortLabel)`
    font-size: 0.75rem;
    font-weight: 700;

    svg {
        font-size: 0.75rem;
    }
`;

const SortHidden = styled.span`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1;
    margin: -1;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 20;
    width: 1;
`;

const SpanState = styled.span`
    font-size: 0.8rem;
    text-transform: capitalize;
    padding: 0.25rem 0.75rem;
    border-radius: 50px;
    width: max-content;
    color: #ffffff;
    background-color: ${p => (p.background === 'activo' ? '#1e8065'
    : p.background === 'pendiente' ? '#f2ab1f'
    : p.background === 'bloqueado' ? '#b31531'
    : p.background === 'vencido' && '#b31531' )};
`;
