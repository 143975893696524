import React from 'react';
import styled from 'styled-components';
import { Skeleton } from '@material-ui/lab';
import Wrapper from '../../shared/common/Wrapper';
import SubjectCardSkeleton from './SubjectCardSkeleton';

const SubjectsSkt = () => {
    return (
        <Container>
            <HeaderWrapper>
                <Wrapper width="65%">
                    <SkeletonStyled variant="rectangular" animation="wave" width="100%" height={65} />
                </Wrapper>
                <Wrapper width="35%">
                    <SkeletonStyled variant="rectangular" animation="wave" width="100%" height={65} />
                </Wrapper>
            </HeaderWrapper>

            <GridValues>
                <SubjectCardSkeleton />
                <SubjectCardSkeleton />
                <SubjectCardSkeleton />
                <SubjectCardSkeleton />
                <SubjectCardSkeleton />
                <SubjectCardSkeleton />
            </GridValues>
        </Container>
    );
};

export default SubjectsSkt;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    padding: 0;
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1.5rem;
    background-color: transparent !important;
    width: 100%;
    padding: 0;
    div {
        background-color: transparent !important;
        border-radius: 20px;
    }
`;

const SkeletonStyled = styled(Skeleton)`
    border-radius: 20px;
`;

const GridValues = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    height: auto;
    grid-gap: 1.5rem;
    padding: 0.5rem;
    justify-content: center;
    align-items: flex-start;
    justify-items: center;
    align-content: stretch;
    @media screen and (max-width: 425px) {
        display: flex;
        flex-direction: column;
        column-gap: 1rem;
    }
`;
