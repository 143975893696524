import React from 'react';
import styled from 'styled-components';

import Text from '../../../shared/common/Text';
import { Button } from '@material-ui/core';

const EditGroupSuccess = props => {
    const { handleClose } = props;

    // RETURN
    return (
        <>
            <FakeImage src="/images/create-group-success.svg" alt="avatar" />
            <TextWrapper>
                <Text fontSize="1.5rem" fontWeight="700">
                    ¡Perfecto!
                </Text>
                <Text fontSize="1rem" fontWeight="700">
                    El grupo fue editado con éxito
                </Text>
                <FakeButton variant="contained" color="primary" size="small" onClick={handleClose}>
                    Ok
                </FakeButton>
            </TextWrapper>
        </>
    );
};

export default EditGroupSuccess;

const FakeImage = styled.img`
    width: 250px;
    height: auto;
    margin-top: 1rem;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    text-align: center;
`;

const FakeButton = styled(Button)`
    margin-top: 0.5rem;
    width: 100%;
`;
