import React from 'react';
import Text from '../../../shared/common/Text';
import { TextField } from '@material-ui/core';
import { CenterSearchMember, ContainerGroupsPage } from '../styled-components/groups.styled.components';
import Wrapper from '../../../shared/common/Wrapper';

const SearchName = props => {
    const { nombre, handleName } = props;

    // RETURN
    return (
        <ContainerGroupsPage>
            <Text fontSize="1rem" fontWeight="700" color="primary">
                Nombre del grupo
            </Text>
            <Wrapper>
                <CenterSearchMember>
                    {' '}
                    <TextField
                        value={nombre}
                        onChange={handleName}
                        required
                        type="text"
                        variant="outlined"
                        color="primary"
                        placeholder="Nombre de grupo"
						style={{ width: '100%' }}
                    />
                </CenterSearchMember>
            </Wrapper>
        </ContainerGroupsPage>
    );
};

export default SearchName;
