import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Text from '../../shared/common/Text';
import styled from 'styled-components';
import InputIcon from '../../shared/common/InputIcon';
import { useSelector } from 'react-redux';
import NoResultsFound from '../../shared/common/NoResultsFound';
import CheckCourseCard from './CheckCourseCard';
import Loading from '../../shared/common/Loading';
import { Button } from '@material-ui/core';
import { getResourcesAdministrator } from '../../pages/Resources/services/resources.services';
import { resourcesAdapter } from '../../pages/Resources/adapters/resources.adapter';
import FilterResource from '../../pages/Resources/components/header/FilterResource';
import { PaginationContainer } from '../../pages/Resources/styled-components/resources.styled.components';
import { Icon } from '@iconify/react/dist/iconify.js';
import DefaultStateFilter from '../filters/DefaultStateFilter';

const ModalResourceAggregation = props => {
    const { open, handleClose, handleResourceAggregation, changeChecked } = props;
    const ondemand_id = useSelector(state => state?.user?.user?.id);

    // console.log('changeChecked :>> ', changeChecked);

    //STATES
    const [currentState, setCurrentState] = useState({ name: 'ADEN', value: 'ADEN' });
    const [allResources, setAllResources] = useState([]);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState({ name: 'Audiovisuales', value: 'audiovisual' });
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [checkedItems, setCheckedItems] = useState([]);
    const [itemsToGrid, setItemsToGrid] = useState(changeChecked);

    const PAGE_SIZE = 10;
    const resourceOrigin = [
        { id: 0, name: 'ADEN', value: 'ADEN' },
        { id: 1, name: 'EMPRESA', value: 'EMPRESA' },
    ];

    //prefiltrado por origen y tipo de recurso, ademas por buscador
    const filteredResources = allResources.filter(resource => {
        if (search === '') return true;
        return resource.title?.toLowerCase().includes(search.toLowerCase());
    });

    const paginatedResources = filteredResources.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);

    //EFFECTS
    useEffect(() => {
        const totalPages = Math.ceil((search === '' ? allResources.length : filteredResources.length) / PAGE_SIZE);
        setTotalPages(totalPages);
    }, [allResources, filteredResources, search]);

    useEffect(() => {
        setCurrentPage(1);
        handleResources();
        console.log('currentState :>> ', currentState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, currentState]);

    //seleccionado al menos un item envía a la grilla de OA
    useEffect(() => {
        if (itemsToGrid.length > 0) {
            // console.log('itemsToGrid :>> ', itemsToGrid);
            handleResourceAggregation(itemsToGrid);
        }
    }, [itemsToGrid]);

    //FUNCTIONS

    const handleResources = async () => {
        setLoading(true);
        let req = await getResourcesAdministrator(ondemand_id, filter.value);

        if (!!req) {
            currentState.name === 'ADEN'
                ? setAllResources(resourcesAdapter(req?.aden_resources))
                : setAllResources(resourcesAdapter(req?.company_resources));
            setLoading(false);
        }
    };

    function handleSearch(e) {
        setSearch(e.target.value);
    }

    //filtro por tipo de recurso
    function handleFilter(e) {
        setFilter(e);
    }

    const handlePagination = page => {
        setCurrentPage(page);
    };

    function handleChecked(e) {
        if (e.target.checked) {
            setCheckedItems([...checkedItems, { id: Number(e.target.name) }]);
        } else {
            setCheckedItems(checkedItems.filter(x => x.id !== Number(e.target.name)));
        }
    }

    const handleEstructure = () => {
        //cambia el listado de recursos "allResources" ante los filtros seleccionados (por tipo y origen de recurso)
        const processedItems = checkedItems
            .map(checkItem => {
                return allResources.filter(recurso => recurso.id === checkItem.id || recurso.id_recurso === checkItem.id);
            })
            .flat();
        //amplia el array de itemsToGrid con los items seleccionados => corregir cuando se deselecciona un item
        setItemsToGrid([...changeChecked, ...processedItems]);
        console.log('handleEstructure_processedItems :>> ', processedItems);
        // handleResourceAggregation(processedItems);
        handleClose();
    };

    //selector de recurso customizado o no
    const handleCurrentState = newValue => {
        // console.log('newValue', newValue);
        const estadoEncontrado = resourceOrigin.find(origin => origin.name === newValue);
        if (estadoEncontrado) {
            setCurrentState(estadoEncontrado);
        }
    };

    //RETURN
    if (allResources.length === 0) {
        return <Loading />;
    }
    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Container>
                <Text fontSize="1.125rem" fontWeight="700" lineheight="1.5rem" fontcolor="#000000">
                    Cursos disponibles
                </Text>
                <Text fontSize="0.8rem" fontWeight="400" lineheight="1.5rem" fontcolor="#616161">
                    Selecciona los cursos que formarán parte del módulo.
                </Text>
                <HeaderWrapper>
                    <InputIcon value={search} onChange={handleSearch} />
                    <DefaultStateFilter currentState={currentState} handleCurrentState={handleCurrentState} estados={resourceOrigin} />
                    <FilterResource filter={filter} handleFilter={handleFilter} />
                </HeaderWrapper>{' '}
                {paginatedResources.length !== 0 ? (
                    <GridValues>
                        {paginatedResources.map(value => (
                            // value.id !== undefined &&
                            <CheckCourseCard
                                value={value}
                                key={value.id}
                                artId={value.id}
                                type="customCourse"
                                handleChecked={handleChecked}
                                checked={checkedItems.some(x => x.id === value.id)}
                            />
                        ))}
                    </GridValues>
                ) : search.length === 0 ? (
                    //     <NoResultsFound message="No existen aún recursos generados por la empresa, añade uno." />
                    // ) : (
                    <NoResultsFound message="No se encuentran resultados para tu búsqueda" />
                ) : null}
                <PaginationContainer>
                    <button
                        onClick={() => {
                            handlePagination(1);
                        }}>
                        <Icon icon="lucide:arrow-left-to-line" width="1.2em" height="1.2em" />
                    </button>
                    <button
                        onClick={() => {
                            if (currentPage !== 1) {
                                handlePagination(currentPage - 1);
                            }
                        }}>
                        <Icon icon="heroicons-outline:arrow-left" width="1.2em" height="1.2em" />
                    </button>
                    <p>
                        Página <b>{currentPage}</b> de {totalPages}
                    </p>
                    <button
                        onClick={() => {
                            if (currentPage !== totalPages) {
                                handlePagination(currentPage + 1);
                            }
                        }}>
                        <Icon icon="heroicons-outline:arrow-right" width="1.2em" height="1.2em" />
                    </button>
                    <button
                        onClick={() => {
                            handlePagination(totalPages);
                        }}>
                        <Icon icon="lucide:arrow-right-to-line" width="1.2em" height="1.2em" />
                    </button>
                </PaginationContainer>
                <ButtonWrapper>
                    <Button variant="contained" color="primary" size="small" disabled={checkedItems.length === 0} onClick={handleEstructure}>
                        Continuar
                    </Button>
                </ButtonWrapper>
            </Container>
        </Modal>
    );
};

export default ModalResourceAggregation;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    border: none;
    background-color: #ffffff;
    border-radius: 1.25rem;
    height: 80vh;
    width: calc(60% - 4rem);
    padding: 2rem;
    overflow: hidden;
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 0;
    align-items: center;
    gap: 1.5rem;
    justify-content: space-between;
`;

const GridValues = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
    align-items: flex-start;
    justify-items: start;
    align-content: stretch;
    gap: 1rem;
    padding: 1rem;
    overflow-y: scroll;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
    padding: 1rem 0 0 0;
`;
