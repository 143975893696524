import React from 'react';
import Text from '../../../shared/common/Text';
import { ImageStyle, TextMessageWrapper } from '../styled-components/groups.styled.components';
import { ButtonFilledDark } from '../../../styled-components/buttons.styled.components';

const AddGroupSuccess = props => {
    const { handleClose } = props;

    // RETURN
    return (
        <>
            <ImageStyle src="/images/create-group-success.svg" alt="avatar" />
            <TextMessageWrapper>
                <Text fontSize="1.5rem" fontWeight="700">
                    ¡Perfecto!
                </Text>
                <Text fontSize="1rem" fontWeight="700">
                    El grupo fue creado con éxito
                </Text>
                <ButtonFilledDark variant="contained" color="primary" size="small" onClick={handleClose}>
                    Ok
                </ButtonFilledDark>
            </TextMessageWrapper>
        </>
    );
};

export default AddGroupSuccess;