import React from "react";

import DefaultTable from "../../../shared/common/DefaultTable";

const headCells = [
	{ id: "nombre", label: "NOMBRE GRUPO" },
	// { id: "fecha_creación", label: "FECHA DE CREACIÓN" },
	{ id: "cant_integrantes", label: "CANTIDAD DE INTEGRANTES" },
	//{ id: "cursos_asignados", label: "CURSOS ASIGNADOS" },
	// { id: "ultimo_acceso", label: "ÚLTIMO ACCESO" },
	// { id: "estado", label: "ESTADO USUARIO" },
];

const TableGroups = (props) => {
	const { groups } = props;
	// RETURN
	return (
		<>
			<DefaultTable 
			rows={groups} 
			headCells={headCells} 
			type="group" />
		</>
	);
};

export default TableGroups;
