import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { participantActions } from '../../redux/actions';
import { updateCustomOA } from '../../redux/api/content-creation.api';
import RemoveUsers from '../content/RemoveUsers';
import Snack from '../../shared/common/Snack';
import MessageModal from '../../shared/common/MessageModal';
import EnrolledParticipants from '../content/EnrolledParticipants';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { Menu, MenuItem } from '@material-ui/core';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import GroupRemoveRoundedIcon from '@mui/icons-material/GroupRemoveRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ViewCourse from '../content/ViewCourse';
import { useModal } from '../../hooks/ModalContext';

const CustomCourseAlabCard = props => {
    const { course, deleted, publishedStateChanged } = props;

    let history = useHistory();

    // REDUX
    const dispatch = useDispatch();

    //STATES
    const [changePublishedState, setChangePublishedState] = useState(null);
    const [contentMessage, setContentMessage] = useState(null);
    const [errorOpen, setErrorOpen] = useState(false);

    const [assignSuccess, setAssignSuccess] = useState(false);
    const [removeSuccess, setRemoveSuccess] = useState(false);
    const [deletedSuccess, setDeletedSuccess] = useState(false);

    //menu izq desplegable
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    //manejo de modales
    const { state, openModal, closeModal } = useModal();
    const handleOpenModal = modalType => {
        openModal(modalType);
    };
    const handleCloseModal = () => {
        closeModal();
    };

    const publishedState = course?.published;

    //EFFECTS
    //publicacion
    useEffect(() => {
        if (!!changePublishedState) {
            handleOpenModal('entendido');
            setContentMessage({
                message: `${publishedState === false ? 'Publicado' : 'Despublicado'}`,
                image: '/images/published_article.svg',
                subtitle: `El curso fue ${publishedState === false ? ' publicado ' : ' despublicado '}con éxito`,
            });

            setTimeout(() => {
                publishedStateChanged();
                setChangePublishedState(null);
            }, 2000);
        }
        //eslint-disable-next-line
    }, [changePublishedState]);

    //matriculacion exitosa
    useEffect(() => {
        if (!!assignSuccess) {
            handleOpenModal('entendido');
            setContentMessage({
                message: '¡Matriculación exitosa!',
                image: '/images/matriculacion_usuario.svg',
                subtitle: 'El curso fue asignado a los alumnos seleccionados de manera correcta.',
            });

            setTimeout(() => {
                dispatch(participantActions.confirmAssignCourse()); //cambia a false el estado de assign del course
                setAssignSuccess(false);
            }, 500);
        }
        //eslint-disable-next-line
    }, [assignSuccess, dispatch]);

    //desmatriculacion exitosa
    useEffect(() => {
        if (!!removeSuccess) {
            handleOpenModal('entendido');
            setContentMessage({
                message: '¡Desmatriculación exitosa!',
                image: '/images/desmatriculacion_usuario.svg',
                subtitle: 'El curso fue desasignado a los alumnos seleccionados de manera correcta.',
            });

            setTimeout(() => {
                setRemoveSuccess(false);
            }, 500);
        }
        // eslint-disable-next-line
    }, [removeSuccess, dispatch]);

    //eliminacion de curso exitosa
    useEffect(() => {
        if (!!deletedSuccess) {
            handleOpenModal('entendido');
            setContentMessage({
                message: 'Eliminado',
                image: '/images/delete_article.png',
                subtitle: 'El curso fue eliminado con éxito.',
            });
            deleted(course.id);

            setTimeout(() => {
                setDeletedSuccess(false);
            }, 500);
        }
        //eslint-disable-next-line
    }, [deletedSuccess, dispatch]);

    // FUNCTIONS
    function handleCloseError() {
        setErrorOpen(false);
    }

    //Publicación o despublicación de curso
    const handlePublishState = async () => {
        //estado publicado: accion "Despublicar"
        if (!!publishedState) {
            await handleUpdatePublish(false, course.id);
            console.log('Despublicar', publishedState);
        } else {
            //estado despublicado: accion "Publicar"
            await handleUpdatePublish(true, course.id);
            console.log('Publicar', publishedState);
        }
    };

    //Publicación o despubicaciòn desde boton _ action debe ser tru o false
    const handleUpdatePublish = async (action, art) => {
        // console.log('action', action);
        const data = {
            published: action,
        };
        let response = await updateCustomOA(art, data);
        console.log('response - handleUpdatePublish', response);

        if (response.error) {
            setErrorOpen(true);
            <Snack open={errorOpen} handleClose={handleCloseError} severity="warning" color="#f44336">
                El curso personalizado no pudo publicarse, favor de contactarse con el administrador.
            </Snack>;
        } else {
            setChangePublishedState(true);
        }
    };

    //eliminación confirmada por modal _ publicacion del resultado del ep
    const handleConfirmedDeleteCourse = () => {
        console.log('confirma eliminacion de curso');
        handleDeleteCourse();
        setTimeout(() => {
            console.log('deletedSuccess', deletedSuccess);
        }, 500);
    };

    const handleDeleteCourse = () => {
        console.log('Aguardando EP', deletedSuccess);
        // let response = await deleteCustomOA(art);
        // if (response.error) {
        //     setErrorOpen(true);
        //     <Snack open={errorOpen} handleClose={handleCloseError} severity="warning" color="#f44336">
        //         El Curso personalizado no pudo eliminarse, favor de contactarse con el administrador.
        //     </Snack>;
        // } else {
        // setDeletedSuccess(true)
        //      handleCloseModal();

        // }
    };

    //edición de curso _  SIN MODAL
    const handleEditCourse = () => {
        history.push(`/edit-custom-course/${course.id}`);
    };

    // console.log('publishedState', publishedState);
    return (
        <>
            <CardFake>
                <ButtonState bgc={publishedState}>
                    {publishedState === true ? (
                        <>
                            {' '}
                            <CheckRoundedIcon /> Publicado{' '}
                        </>
                    ) : (
                        <>
                            {' '}
                            <EditRoundedIcon /> Borrador{' '}
                        </>
                    )}
                </ButtonState>
                <Header image={course?.imagen}>
                    {course?.tag_id[0] || course?.areas || course?.recursos ? (
                        <TagSpan>
                            {course?.areas} Areas - {course?.recursos} Recursos {course?.tag_id[0]}{' '}
                        </TagSpan>
                    ) : null}
                </Header>

                <BodyCard>
                    <h2>{course?.nombre_publicacion}</h2>
                    <Span>
                        {course?.autor !== '' && course?.autor !== undefined && course?.autor !== null && course?.autor !== false ? (
                            <AutorSpan>Autor: {course?.autor}</AutorSpan>
                        ) : null}
                    </Span>
                </BodyCard>

                <Footer>
                    <>
                        <MoreVertRoundedIcon
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        />
                        <MenuFake
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleCloseMenu}
                            MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                            <MenuItem onClick={handleEditCourse}>
                                <EditRoundedIcon />
                                Editar
                            </MenuItem>
                            <MenuItem onClick={() => handleOpenModal('previsualizar')}>
                                <RemoveRedEyeIcon />
                                Vista previa
                            </MenuItem>
                            <MenuItem onClick={() => handleOpenModal('matricular')}>
                                <GroupAddIcon />
                                Matricular alumnos
                            </MenuItem>
                            <MenuItem onClick={() => handleOpenModal('desmatricular')}>
                                <GroupRemoveRoundedIcon />
                                Desmatricular alumnos
                            </MenuItem>
                            <MenuItem onClick={() => handleOpenModal('eliminar')}>
                                <GroupRemoveRoundedIcon />
                                Eliminar
                            </MenuItem>
                        </MenuFake>
                    </>
                    <ButtonToPublish bgc={publishedState} onClick={handlePublishState}>
                        {!!publishedState ? 'Despublicar' : 'Publicar'}
                    </ButtonToPublish>
                </Footer>
            </CardFake>

            {state.isOpen &&
                state.modalType === 'entendido' && ( //mensaje entendido
                    <MessageModal
                        isOpen={state.isOpen}
                        handleClose={handleCloseModal}
                        message={contentMessage?.message}
                        image={contentMessage?.image}
                        subtitle={contentMessage?.subtitle}
                    />
                )}

            {state.isOpen &&
                state.modalType === 'matricular' && ( //modal de matriculación
                    <EnrolledParticipants
                        content={course}
                        type={'course'}
                        openModal={state.isOpen}
                        closeModal={handleCloseModal}
                        success={() => setAssignSuccess(true)}
                    />
                )}

            {state.isOpen &&
                state.modalType === 'previsualizar' && ( //modal de previsualización
                    <ViewCourse course={course} openModal={state.isOpen} closeModal={handleCloseModal} />
                )}

            {state.isOpen &&
                state.modalType === 'desmatricular' && ( //modal de desmatriculación
                    <RemoveUsers
                        course={course}
                        type={'course'}
                        openModal={state.isOpen}
                        closeModal={handleCloseModal}
                        removeSucces={() => setRemoveSuccess(true)}
                    />
                )}

            {state.isOpen && state.modalType === 'eliminar' && (
                <MessageModal
                    isOpen={state.isOpen}
                    handleClose={handleCloseModal}
                    message="¿Deseas eliminar?"
                    image="/images/eliminacion_modulo.svg"
                    subtitle="Estás a punto de eliminar un curso"
                    handleClickConfirm={handleConfirmedDeleteCourse}
                />
            )}
        </>
    );
};

export default CustomCourseAlabCard;

const CardFake = styled.div`
    min-width: 350px;
    height: 340px;
    padding: 0;
    max-width: 320px;
    border-radius: 20px;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    position: relative;
    background-color: #fafafa;
`;

const Header = styled.div`
    height: 100px;
    padding: 1.5rem;
    border-radius: 20px 20px 0 0;
    width: calc(100% - 3rem);
    background-image: url(${p => `${p.image}`});
    background-size: cover;
    background-position: center center;
    position: relative;
`;

const BodyCard = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.8rem;
    width: 290px;
    overflow: hidden;
    white-space: nowrap;
    h2 {
        color: #222222;
        font-size: 1.2rem;
        text-overflow: ellipsis;
        padding: 0.5rem;
    }
`;

const Span = styled.span`
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 140%;
    color: #616161;
    display: flex;
    align-items: flex-end;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    text-overflow: ellipsis;

    svg {
        font-weight: 400;
        font-size: 1.5rem;
        color: #616161;
        opacity: 50%;
        padding: 0 1rem 0 0;
    }
`;

const ButtonState = styled.div`
    background-color: ${p => (!!p.bgc ? '#35D0A5' : '#616161')};
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: max-content;
    padding: 0.3rem 0.5rem;
    gap: 0.25rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #ffffff;

    position: absolute;
    top: 2.5%;
    right: 2.5%;
    z-index: 5;
`;

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    width: calc(100%-4rem);
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    height: calc(100%-2rem);
    gap: 0.8rem;
    position: absolute;
    bottom: 1%;
    left: 1%;
    right: 1%;
    /* background-color: #fafafa; */

    svg {
        fill: var(--secondary-four);
        cursor: pointer;
    }
`;

const TagSpan = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1rem;
    gap: 10px;
    position: absolute;
    width: 50%;
    left: 0px;
    bottom: 0px;
    background: #ffffff;
    border-radius: 0px 10px 0px 0px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 1rem;
    text-align: right;
    color: #616161;
`;

const MenuFake = styled(Menu)`
    ul li span {
        color: var(--secondary-four);
        font-size: 0.8rem;
        font-weight: 400;
        font-family: 'Montserrat';
        font-style: normal;
    }

    .MuiList-root-MuiMenu-list {
        color: var(--secondary-four);
    }

    .MuiSvgIcon-root {
        fill: var(--secondary-four);
        margin-right: 1rem;
    }
    .MuiIconButton-colorPrimary:hover {
        background-color: transparent;
    }

    .MuiIconButton-root:hover {
        background-color: transparent;
    }

    .MuiButtonBase-root {
        width: 100%;
        justify-content: flex-start;
        color: #222222;
        font-size: 0.8rem;
        line-height: 1.5rem;
        font-weight: 500;
        font-family: 'Montserrat';
        font-style: normal;
    }
`;

const ButtonToPublish = styled.div`
    background-color: ${p => (p.bgc === true ? 'var(--tertiary-three)' : 'var(--primary-one)')};
    border-radius: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: max-content;
    padding: 0.5rem 1.5rem;
    gap: 0.5rem;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 1.5rem;
    letter-spacing: 0.001em;
    color: ${p => (p.bgc === true ? 'var(--secondary-three)' : '#FFFFFF')};
`;

const AutorSpan = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem;
    width: 100%;
    gap: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    text-align: left;
    color: #616161;

    svg {
        font-size: 2.5rem;
        padding: 0;
    }
`;
