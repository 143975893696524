import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { participantActions } from '../../redux/actions';
import { useModal } from '../../hooks/ModalContext';

import Layout from '../../shared/layout/Layout';
import Header from '../../shared/layout/header/Header';
import Wrapper from '../../shared/common/Wrapper';
import InputIcon from '../../shared/common/InputIcon';
import MessageModal from '../../shared/common/MessageModal';
import DefaultStateFilter from '../../components/filters/DefaultStateFilter';
import ModalForm from '../../shared/common/ModalForm';
import UploadRoundedIcon from '@mui/icons-material/UploadRounded';
import useFilterParticipants from './hooks/useFilterPArticipants';
import UsersSkt from './components/UsersSkt';
import AddUser from './components/AddUser';
import ExportUsers from './components/ExportUsers';
import TableUsers from './components/TableUsers';
import ModalImportUsers from './components/ModalImportUsers';
import { Container, HeaderWrapper, RowWrapper } from './styled-components/users.styled.components';
import { ButtonFilledDark, ButtonsWrapper } from '../../styled-components/buttons.styled.components';

const Users = () => {
    let history = useHistory();

    // REDUX
    const dispatch = useDispatch();
    const ondemand_id = useSelector(state => state.user.user.id);
    const participants = useSelector(state => state.participant.participants);
    const loadingParticipants = useSelector(state => state.participant.loadingParticipants);

    // STATE
    const [filteredData, setFilteredData] = useState(null);
    const [search, setSearch] = useState('');
    const [currentState, setCurrentState] = useState({ id: 1, days: 1, name: 'Todos', value: 1 });

    //filtro de Ultimo acceso a la plataforma_cda vez que cambia currentState deberia reaccionar
    const { filteredFinalData } = useFilterParticipants(participants, currentState);

    //modales
    const [createSuccess, setCreateSuccess] = useState(false);
    const [createPartialSuccess, setCreatePartialSuccess] = useState(false);
    const [createError, setCreateError] = useState(false);
    const [contentMessage, setContentMessage] = useState(null);

    //manejo de modales
    const { state, openModal, closeModal } = useModal();
    const handleOpenModal = modalType => {
        openModal(modalType);
    };
    const handleCloseModal = () => {
        closeModal();
        history.push(`/users`);
    };

    // EFFECTS
    useEffect(() => {
        if (participants === null) {
            dispatch(participantActions.getParticipantsRequest({ ondemand_id }));
        } else {
            setFilteredData(participants?.length >= 0 ? participants : []);
        }
        // eslint-disable-next-line
    }, [participants]);

    useEffect(() => {
        if (search.length !== 0) {
            searchParticipants(participants, search);
            setCurrentState(1);
        }
        // eslint-disable-next-line
    }, [search]);

    useEffect(() => {
        setFilteredData(filteredFinalData);
    }, [filteredFinalData]);

    const handleCurrentState = newValue => {
        console.log('newValue', newValue);
        const estadoEncontrado = lapsos.find(estado => estado.days === newValue);
        if (estadoEncontrado) {
            setCurrentState(estadoEncontrado);
        }
        setSearch('');
    };

    //Importacion Usuarios
    //importacion exitosa
    useEffect(() => {
        if (!!createSuccess) {
            handleOpenModal('entendido');
            setContentMessage({
                message: '¡Importación de usuarios finalizada!',
                image: '/images/matriculacion_usuario.svg',
                subtitle: 'La importación se realizó exitosamente.',
            });
            setTimeout(() => {
                setCreateSuccess(false);
            }, 500);
        }
        // eslint-disable-next-line
    }, [createSuccess]);

    //importacion PARCIAL
    useEffect(() => {
        if (!!createPartialSuccess) {
            handleOpenModal('entendido');
            setContentMessage({
                message: 'Éxito parcial de importación',
                image: '/images/imagen_error.png',
                subtitle: 'Algunos usuarios fueron importados exitosamente, pero otros quedaron fuera del proceso.',
            });
            setTimeout(() => {
                setCreateSuccess(false);
            }, 500);
        }

        // eslint-disable-next-line
    }, [createSuccess]);

    //importacion error archivo
    useEffect(() => {
        if (!!createError) {
            handleOpenModal('entendido');
            setContentMessage({
                message: '¡No se puede procesar el adjunto!',
                image: '/images/imagen_error.png',
                subtitle: 'Por favor intenta con un csv que respete el formato descargado de la planilla indicada',
            });
            setTimeout(() => {
                setCreateError(false);
            }, 500);
        }
        // eslint-disable-next-line
    }, [createError]);

    // FUNCTIONS
    //busqueda por nombre o email de participante
    function handleSearch(e) {
        setSearch(e.target.value);
    }
    const searchParticipants = (array, search) => {
        let newArray = [];
        array.forEach(item => {
            if (item.nombre?.toLowerCase().includes(search.toLowerCase()) || item?.email?.toLowerCase().includes(search.toLowerCase())) {
                newArray.push(item);
            }
        });
        setFilteredData(newArray.length === 0 ? [] : newArray);
    };

    const lapsos = [
        { id: 5, days: 5, name: 'Último acceso +30 días', value: 5 },
        { id: 4, days: 4, name: 'Último acceso +7 y -30 días', value: 4 },
        { id: 3, days: 3, name: 'Accedieron ésta semana', value: 3 },
        { id: 1, days: 1, name: 'Todos', value: 1 },
        { id: 2, days: 2, name: 'Sin acceso', value: 2 },
    ];

    // RETURN
    return (
        <Layout header={<Header route="Usuarios" />}>
            {participants === null || loadingParticipants || filteredData === null ? (
                <UsersSkt />
            ) : (
                <>
                    <Container>
                        <HeaderWrapper>
                            <RowWrapper>
                                <InputIcon value={search} onChange={handleSearch} />
                                <Wrapper height="4rem" width="max-content">
                                    <ButtonsWrapper>
                                        <ButtonFilledDark
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            startIcon={<UploadRoundedIcon />}
                                            onClick={() => handleOpenModal('importar')}>
                                            <span>Importar usuarios</span>
                                        </ButtonFilledDark>
                                        <AddUser />
                                        <ExportUsers participants={filteredData} />
                                    </ButtonsWrapper>
                                </Wrapper>
                            </RowWrapper>
                            <DefaultStateFilter currentState={currentState} handleCurrentState={handleCurrentState} estados={lapsos} />
                        </HeaderWrapper>
                        <TableUsers id="table-users" participants={filteredData} />
                    </Container>
                    {state.isOpen &&
                        state.modalType === 'importar' && ( //modal de importacion masiva de usuarios
                            <ModalForm
                                isOpen={state.isOpen}
                                handleClose={handleCloseModal}
                                banner={'var(--primary-one)'}
                                title={'Importación de usuarios'}>
                                <ModalImportUsers
                                    ondemand_id={ondemand_id}
                                    success={() => setCreateSuccess(true)}
                                    error={() => setCreateError(true)}
                                />
                            </ModalForm>
                        )}
                    {state.isOpen &&
                        state.modalType === 'entendido' && ( //mensaje entendido
                            <MessageModal
                                isOpen={state.isOpen}
                                handleClose={handleCloseModal}
                                message={contentMessage?.message}
                                image={contentMessage?.image}
                                subtitle={contentMessage?.subtitle}
                            />
                        )}
                </>
            )}
        </Layout>
    );
};

export default Users;
