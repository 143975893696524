import React, { useState } from 'react';
import { GroupAddRounded } from '@material-ui/icons';
import ModalForm from '../../../shared/common/ModalForm';
import { useDispatch, useSelector } from 'react-redux';
import FormAddGroup from './FormAddGroup';
import AddGroupSuccess from './AddGroupSuccess';
import { groupActions } from '../../../redux/actions';
import { ContainerFormGroup } from '../styled-components/groups.styled.components';
import { ButtonFilledDark } from '../../../styled-components/buttons.styled.components';

const AddGroup = () => {
    // REDUX
    const dispatch = useDispatch();

    const newGroup = useSelector(state => state.group.newGroup);

    // STATE
    const [isOpen, setIsOpen] = useState(false);

    // FUNCTIONS
    function handleOpen() {
        setIsOpen(true);
    }

    function handleClose() {
        setIsOpen(false);
        dispatch(groupActions.confirmCreateGroup());
    }

    // RETURN
    return (
        <>
            <ButtonFilledDark variant="contained" color="primary" size="small" startIcon={<GroupAddRounded />} onClick={handleOpen}>
                Añadir grupo
            </ButtonFilledDark>
            <ModalForm isOpen={isOpen} handleClose={handleClose} title={newGroup ? '' : 'Nuevo grupo'}>
                <ContainerFormGroup formGroup={newGroup}>
                    {newGroup ? <AddGroupSuccess handleClose={handleClose} /> : <FormAddGroup />}
                </ContainerFormGroup>
            </ModalForm>
        </>
    );
};

export default AddGroup;
