import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Divider, TextField } from '@material-ui/core';
import Loading from '../../../shared/common/Loading';
import Text from '../../../shared/common/Text';
import SearchMember from './SearchMember';
import GridMembers from '../../../shared/common/GridMembers';
import { groupActions, participantActions } from '../../../redux/actions';
import { FormAddGroupContainer, FormAddGroupWrapper, FormAddGroupWrapperCheck } from '../styled-components/groups.styled.components';
import { ButtonFilledDark, ButtonWrapper } from '../../../styled-components/buttons.styled.components';

const FormEditGroup = props => {
    const { group } = props;
    const { id } = useParams();

    const getInitialChecked = () =>
        group.participantes.map(g => ({
            id: g.participante_id,
        }));

    // REDUX
    const dispatch = useDispatch();
    const ondemand_id = useSelector(state => state.user.user.id);
    const loadingEditGroup = useSelector(state => state.group.loadingEditGroup);
    const participants = useSelector(state => state.participant.participants);

    //EFECT
    useEffect(() => {
        if (participants === null) {
            dispatch(participantActions.getParticipantsRequest({ ondemand_id }));
        }
    }, []);

    //STATE
    const [search, setSearch] = useState('');
    const [nombre, setNombre] = useState(group?.nombre ? group.nombre : '');
    const [checkedItems, setCheckedItems] = useState(getInitialChecked);

    // FUNCTIONS
    function handleSearch(e) {
        setSearch(e.target.value);
    }

    function handleName(e) {
        e.preventDefault();
        setNombre(e.target.value);
    }

    function handleChecked(e) {
        if (e.target.checked) {
            setCheckedItems([...checkedItems, { id: Number(e.target.name) }]);
        } else {
            setCheckedItems(checkedItems.filter(x => x.id !== Number(e.target.name)));
        }
    }

    function handleAllChecked(e) {
        if (e.target.checked) {
            setCheckedItems(participants.map(p => ({ id: p.participante_id })));
        } else {
            setCheckedItems([]);
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        dispatch(
            groupActions.editGroupRequest({
                editGroup: {
                    data_grupo: {
                        nombre,
                    },
                    data_participantes: checkedItems,
                },
                ondemand_id,
                id,
            }),
        );
    }

    //RETURN
    if (group === null) {
        return <Loading />;
    }
    return (
        <FormAddGroupContainer onSubmit={handleSubmit}>
            <Text fontSize="1rem" fontWeight="700" color="primary">
                Nombre del grupo
            </Text>
            <TextField value={nombre} onChange={handleName} required type="text" variant="outlined" color="primary" placeholder={group.nombre} />
            <FormAddGroupWrapper>
                <SearchMember search={search} handleSearch={handleSearch} />
                <FormAddGroupWrapperCheck>
                    <Text fontSize="1rem" fontWeight="700" color="primary">
                        Seleccionar <br /> Todos{' '}
                    </Text>
                    <Checkbox onChange={handleAllChecked} color="primary" />
                </FormAddGroupWrapperCheck>
            </FormAddGroupWrapper>
            <GridMembers height="200px" search={search} handleChecked={handleChecked} checkedItems={checkedItems} />
            <Divider />
                        <ButtonWrapper alignSelf= "flex-end">
            
                <ButtonFilledDark variant="contained" color="primary" size="small" type="submit" disabled={loadingEditGroup}>
                    Guardar cambios
                </ButtonFilledDark>
            </ButtonWrapper>
        </FormAddGroupContainer>
    );
};

export default FormEditGroup;
